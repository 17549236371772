.tile {
    width: 20rem
}

.header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header > * {
    margin: 0 10px;
}

.header button:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.patient-info-bar {
    background-color: #f5f5f5; /* Or another color */
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.list-group-item.calculator-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-group-item.calculator-list > span{
    padding-right: .5rem;
}

.list-group-overflow {
    max-height: 300px; /* Adjust this value as needed */
    overflow-y: scroll;
}

.calculator-list:hover {
    background-color: #f5f5f5; /* Light gray color, you can adjust */
    cursor: pointer;
}

.calculator-list:active {
    background-color: #e0e0e0; /* Slightly darker gray, you can adjust */
}


